import HeuteAlarmAusschluesse from '@/components/settings/Heute/HeuteAlarmAusschluesse.vue';
import { defineComponent } from 'vue';
import HeuteAlarmTable from '@/components/tables/settings/Heute/HeuteAlarmTable.vue';
import HeuteTerminzeiten from '@/components/settings/Heute/HeuteTerminzeiten.vue';
import HeuteGruppenFilter from '@/components/settings/Heute/HeuteGruppenFilter.vue';
import Helptext from '@/components/Helptext.vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import HeuteSonstiges from '@/components/settings/Heute/HeuteSonstiges.vue';
export default defineComponent({
  components: {
    Helptext: Helptext,
    HeuteAlarmAusschluesse: HeuteAlarmAusschluesse,
    HeuteAlarmTable: HeuteAlarmTable,
    HeuteTerminzeiten: HeuteTerminzeiten,
    HeuteGruppenFilter: HeuteGruppenFilter,
    HeuteSonstiges: HeuteSonstiges
  },
  data: function data() {
    return {
      heuteField: [],
      helptext: "Hier kannst Du beeinflu\xDFen wie stark ein Fehler in den Feldern auf den Score wirkt.\n    Eine h\xF6here Zahl bedeutet dabei einen gr\xF6\xDFeren Score-Abzug. Wenn Du den Score-Abzug auf 0 stellst, wird das Feld nur noch rot dargestellt, aber kein Abzug mehr berechnet. W\xE4hlst Du - aus, so wird auch kein Hinweis mehr dargestellt. Mit Klick auf das Auge wird das Feld inklusive Wert gar nicht dargestellt und auch kein Score-Abzug vorgenommen."
    };
  },
  created: function created() {
    if (editSettingsStore.getters.heuteField) {
      this.heuteField = editSettingsStore.getters.heuteField;
    }
  }
});