import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {};
  },
  computed: {
    klassifizierung: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.state.editSettings.heuteSettings) === null || _a === void 0 ? void 0 : _a.klassifizierungBeiPatientAnzeigen;
      },
      set: function set(value) {
        editSettingsStore.commit.updateKlassifizierung(!!value);
      }
    },
    terminstatusTheme: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.state.editSettings.heuteSettings) === null || _a === void 0 ? void 0 : _a.terminstatusTheme;
      },
      set: function set(theme) {
        editSettingsStore.commit.updateTerminstatusTheme(theme);
      }
    },
    weiteAnreise: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.state.editSettings.heuteSettings) === null || _a === void 0 ? void 0 : _a.weiteAnreiseInKm;
      },
      set: function set(value) {
        editSettingsStore.commit.updateWeiteAnreiseInKm(value);
      }
    }
  },
  methods: {}
});