/* unplugin-vue-components disabled */import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heute-klassifizierung py-4"},[_c(VCard,{attrs:{"elevation":"2"}},[_c(VCardTitle,[_c('h3',[_vm._v("Sonstiges")])]),_c(VCardText,[_c(VRow,{staticClass:"align-baseline mt-2"},[_c(VCol,{staticClass:"align-baseline",attrs:{"cols":"1"}},[_c('h4',[_vm._v("Weite Anreise")])]),_c(VCol,{staticClass:"align-baseline",attrs:{"cols":"11"}},[_c('div',{staticClass:"d-flex flex-shrink-1"},[_c(VTextField,{staticStyle:{"max-width":"410px"},attrs:{"type":"number","dense":"","suffix":"km","label":"Entfernung","persistent-hint":"","hint":"Die Entfernung, ab der eine Anreise als \"weite Anreise\" gilt"},model:{value:(_vm.weiteAnreise),callback:function ($$v) {_vm.weiteAnreise=$$v},expression:"weiteAnreise"}})],1)])],1),_c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"justify-center",attrs:{"cols":"1"}},[_c('div',{staticClass:"d-flex"},[_c('h4',[_vm._v("Klassifizierung")])])]),_c(VCol,{staticClass:"justify-center",attrs:{"cols":"11"}},[_c(VSwitch,{attrs:{"hide-details":"","label":"Die Patientenklassifizierung hinter der Patienten-Id anzeigen"},model:{value:(_vm.klassifizierung),callback:function ($$v) {_vm.klassifizierung=$$v},expression:"klassifizierung"}})],1)],1),_c(VRow,{staticClass:"align-baseline"},[_c(VCol,{staticClass:"align-baseline",attrs:{"cols":"1"}},[_c('h4',[_vm._v("Terminstatus-Theme")])]),_c(VCol,{staticClass:"align-baseline",attrs:{"cols":"11"}},[_c('div',{staticClass:"d-flex flex-shrink-1"},[_c(VRadioGroup,{attrs:{"mandatory":"","hint":"Der Status in Heute kann verschieden dargestellt/eingefärbt werden. Wählen Sie hier welche Darstellung Sie bevorzugen.","persistent-hint":""},model:{value:(_vm.terminstatusTheme),callback:function ($$v) {_vm.terminstatusTheme=$$v},expression:"terminstatusTheme"}},[_c(VRadio,{attrs:{"value":"none","label":"Kein Theme"}}),_c(VRadio,{attrs:{"value":"z1timer","label":"Z1-Timer"}})],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }